import type { WorkspaceGenericInputCreateRequest, WorkspaceInputCreateRequest } from 'googleworkspace/types';
import type { FormData } from 'googleworkspace/context/FormData';

export const toWorkspaceInputCreateRequest = ({
  accountId,
  serviceAccountKey,
  logs,
  workspaceName,
  workspaceThrottleEnabled,
  pollingInterval,
  clientId,
  accountUser,
}: FormData): WorkspaceInputCreateRequest => ({
  name: workspaceName.value,
  log_types: logs.value.split(','),
  polling_interval: pollingInterval.value,
  polling_time_unit: 'MINUTES',
  workspace_client_id: clientId.value,
  workspace_account_id: accountId.value,
  enable_throttling: !!workspaceThrottleEnabled?.value,
  workspace_account_user: accountUser.value,
  workspace_service_account_key: serviceAccountKey.fileContent,
});

export const toGenericInputCreateRequest = ({
  accountId,
  serviceAccountKey,
  logs,
  workspaceName,
  clientId,
  workspaceThrottleEnabled,
  pollingInterval,
  accountUser,
}: FormData): WorkspaceGenericInputCreateRequest => ({
  type: 'org.graylog.enterprise.integrations.googleworkspace.WorkspaceInput',
  title: workspaceName.value,
  global: false,
  configuration: {
    log_types: logs.value.split(','),
    polling_interval: pollingInterval.value,
    polling_time_unit: 'MINUTES',
    client_id: clientId.value,
    account_id: accountId.value,
    throttling_allowed: !!workspaceThrottleEnabled?.value,
    account_user: accountUser.value,
    service_account_key: serviceAccountKey.fileContent,
  },
});
