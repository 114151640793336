import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import ValidatedInput from './common/ValidatedInput';
import FormWrap from './common/FormWrap';
import { FormDataContext } from './context/FormData';
import formValidation from './utils/formValidation';

import handleFileUpload from '../helpers/fileReader';

const StepAuthorize = ({ onSubmit, onChange }) => {
  const { formData } = useContext(FormDataContext);

  const isFormValid = formValidation.isFormValid([
    'gcpName',
    'projectId',
    'clientId',
    'serviceAccountKey',
  ], formData);

  const onFileUpload = (e) => {
    e.persist();
    handleFileUpload(e.target.files[0], (fileName, fileContent) => onChange(e, { fileName, fileContent }));
  };

  const accountKeyHelpText = formData.serviceAccountKey && formData.serviceAccountKey.fileName ? `Currently using file: ${formData.serviceAccountKey.fileName}.` : 'Service account key JSON.';

  return (
    <FormWrap onSubmit={onSubmit}
              buttonContent="Verify &amp; Proceed"
              disabled={isFormValid}
              title=""
              description="">

      <ValidatedInput id="gcpName"
                      type="text"
                      fieldData={formData.gcpName}
                      onChange={onChange}
                      placeholder="Input Name"
                      label="Input Name"
                      autoComplete="off"
                      help="Select a name of your new input that describes it."
                      required />

      <ValidatedInput id="projectId"
                      type="text"
                      fieldData={formData.projectId}
                      onChange={onChange}
                      label="Project ID"
                      help="The GUID of the project to which the logs belong."
                      autoComplete="off"
                      required />

      <ValidatedInput id="clientId"
                      type="text"
                      onChange={onChange}
                      fieldData={formData.clientId}
                      help="The GUID of your application."
                      label="Application (client) ID"
                      required />

      <ValidatedInput id="serviceAccountKey"
                      type="file"
                      onChange={onFileUpload}
                      fieldData={formData.serviceAccountKey}
                      label="Service account key"
                      help={accountKeyHelpText}
                      accept=".json"
                      required />
    </FormWrap>
  );
};

StepAuthorize.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default StepAuthorize;
