import type { DefenderGenericInputCreateRequest, DefenderInputCreateRequest, FormDataType } from '../types';

export const toDefenderInputCreateRequest = ({
  tenantId,
  clientId,
  defenderName,
  defenderThrottleEnabled,
  pollingInterval,
  clientSecret,
  defenderStoreFullMessage,
}: FormDataType): DefenderInputCreateRequest => ({
  name: defenderName.value,
  polling_interval: pollingInterval.value,
  polling_time_unit: 'MINUTES',
  defender_tenant_id: tenantId.value,
  enable_throttling: !!defenderThrottleEnabled?.value,
  store_full_message: !!defenderStoreFullMessage?.value,
  defender_client_id: clientId.value,
  defender_client_secret: clientSecret.value,
});

export const toGenericInputCreateRequest = ({
  tenantId,
  clientId,
  defenderName,
  defenderThrottleEnabled,
  pollingInterval,
  clientSecret,
  defenderStoreFullMessage,
}: FormDataType): DefenderGenericInputCreateRequest => ({
  type: 'org.graylog.enterprise.integrations.microsoftdefender.DefenderInput',
  title: defenderName.value,
  global: false,
  configuration: {
    polling_interval: pollingInterval.value,
    polling_time_unit: 'MINUTES',
    tenant_id: tenantId.value,
    throttling_allowed: !!defenderThrottleEnabled?.value,
    store_full_message: !!defenderStoreFullMessage?.value,
    client_id: clientId.value,
    client_secret: clientSecret.value,
  },
});
