import type { GCPGenericInputCreateRequest, GCPInputCreateRequest } from 'gcp/types';
import type { FormData } from 'gcp/context/FormData';

export const toGCPInputCreateRequest = ({
  clientId,
  serviceAccountKey,
  logs,
  gcpName,
  gcpThrottleEnabled,
  pollingInterval,
  projectId,
}: FormData): GCPInputCreateRequest => ({
  name: gcpName.value,
  log_types: logs.value.split(','),
  polling_interval: pollingInterval.value,
  polling_time_unit: 'MINUTES',
  gcp_project_id: projectId.value,
  enable_throttling: !!gcpThrottleEnabled?.value,
  gcp_client_id: clientId.value,
  gcp_service_account_key: serviceAccountKey.fileContent,
});

export const toGenericInputCreateRequest = ({
  clientId,
  serviceAccountKey,
  logs,
  gcpName,
  gcpThrottleEnabled,
  pollingInterval,
  projectId,
}: FormData): GCPGenericInputCreateRequest => ({
  type: 'org.graylog.enterprise.integrations.gcp.GCPInput',
  title: gcpName.value,
  global: false,
  configuration: {
    log_types: logs.value.split(','),
    polling_interval: pollingInterval.value,
    polling_time_unit: 'MINUTES',
    project_id: projectId.value,
    throttling_allowed: !!gcpThrottleEnabled?.value,
    client_id: clientId.value,
    service_account_key: serviceAccountKey.fileContent,
  },
});
